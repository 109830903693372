/** Item list page **/

.item-container{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8rem;

  .item-wrapper{
    /*width: 364px;*/
    width:100%;
    height: 233px;
    text-align: left;
    text-transform: uppercase;
    font-size: 17px;
    border: 1px solid $grey-500;
    cursor: pointer;
    .item-image{
      /*width: 350px;*/
      width:100%;
      height: 175px;
      background-size: cover;
      background-color: $grey-100;
    }
  }
}

.item-name > strong, .item-address{
    text-overflow: ellipsis;
    height: 28px;
    overflow: hidden;
    width: 155px;
    display: block;
    white-space: nowrap;
}

/** Map styles **/

//_variables
$header: 92px !default;
$heading-title-bar-height: 100px !default;
$item-height: 240px !default;
$adjustment: 100px !default;

.map-wrapper{
  height: calc(100vh
  - #{$header}
  - #{$heading-title-bar-height}
  - #{$item-height}
  - #{$adjustment}

  );
  min-height: 300px;
}
/** Carousel list **/

@include media-breakpoint-down(sm) {
  .slick-prev{
    left: -10px;
    z-index: 999;
  }
  .slick-next{
    right: -10px;
    z-index: 999;
  }
}
@include media-breakpoint-up(sm) {
  .slick-prev{
    left: -25px;
    z-index: 999;
  }
  .slick-next{
    right: -25px;
    z-index: 999;
  }
}

.slick-prev:before, .slick-next:before{
  color: $black;
}
.slick-slide{

    .item-wrapper{
      /*width: 364px;*/
      width:100%;
      height: 233px;
      text-align: left;
      text-transform: uppercase;
      font-size: 17px;
      border: 1px solid $grey-500;
      cursor: pointer;
      .item-image{
        /*width: 350px;*/
        width:100%;
        height: 175px;
        background-size: cover;
        background-color: $grey-100;
      }
    }
}
.slick-current .item-image{
  border: 5px solid #b71c2c;
}
.carousel-wrapper{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
/** Item Detailed view **/
.carousel-control-prev-icon {
    cursor: pointer;
    background-image: none;
    background-color:#000;
    mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon{
  cursor: pointer;
  background-image: none;
  background-color:#000;
  mask-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}
/** Item category icon **/

.item-category-icon-wrapper{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .3rem;

  .item-category-icon{
    width: 19px;
    height: 19px;
    background-color: $white;
    mask-size: contain;
    mask-repeat: no-repeat;
  }
}
// Section content
.section-html > p{
  margin-bottom: 0;
}
