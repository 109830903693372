/** Layout **/
.logo{
  height: 50px;
}
@media (max-width: 320px) {
  .logo{
    height: 40px;
  }
}
@include media-breakpoint-up(sm){
  .logo{
    height: 60px;
  }
}
@include media-breakpoint-up(md) {
  .logo{
    height: 66px;
  }
}
.main-container{
  margin: 2rem auto;
  width: 100%;
  max-width: 1140px;
}

.app-header{
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}



//Navbar styles
.navbarwrapper {
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    justify-content: center;
    display: flex;
    align-items: center
}

.navbarwrapper .navbar-brand {
    padding: 0;
    margin: 0;
    max-height: 87px;
}

.navbarwrapper .navbar-brand img {
    max-height: 81px;
    margin-left: 15px;

}

.navbarwrapper .headerIcons svg {
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    display: inline-block;
    font-size: 26px;
    font-weight: 100;
    height: 26px;
    letter-spacing: 0.3px;
    line-height: 20px;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    vertical-align: -3.25px;
    width: 22.75px;
}



@include media-breakpoint-up(sm) {
  .navbarwrapper .navbar {
      max-width: 720px;
    }
}

@include media-breakpoint-up(md) {
  .navbarwrapper .navbar {
      max-width:  960px;
    }
}


@include media-breakpoint-up(lg) {
  .navbarwrapper .navbar {
      max-width: 968px;
    }
}


@include media-breakpoint-up(xl) {
  .navbarwrapper .navbar {
      max-width: 1140px;
    }
}


.navbarwrapper .navbar {
    padding: 0;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
}



.navbarwrapper .header-nav {
    height: 100px;
    display: flex;
    align-items: center;
}

.navbarwrapper .menu-item {
    margin: 0 10px;
}

.navbarwrapper li a {
    height: 100vh;
    max-height: 100px !important;

}

.navbarwrapper .menu-item a {
    --primary-color: #446084;
    list-style: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased !important;
    background-color: transparent;
    touch-action: manipulation;
    text-decoration: none;
    padding: 10px 0;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    transition: all .2s;
    letter-spacing: .02em;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: 100;
    height: 100px;
    font-size: 15px;
    color: #000000;
}

.navbarwrapper li.active a,
.navbarwrapper li a.active,
.navbarwrapper li a:hover {
    color: theme-color("primary");
}

.navbarwrapper .header-nav a {
    height: 110px;
    display: flex;
    align-items: center;
}

.navbarwrapper .navbar-brand img {
    max-height: 87px;
}

.header-wrapper:not(.stuck) .logo img {
    transition: max-height .5s;
}

.navbarwrapper .header-nav li {
    margin: 0 10px;
}

.navbarwrapper .custom a {
    margin-top: 15px;
    height: 86px !important;
}

#menu-item-2080 a,
#menu-item-2087 a {
    font-size: 0px;
    width: 32px;
    height: 32px;
    background: url(https://socity.ci/wp-content/uploads/2019/10/cfmaik.png);
}

#menu-item-2080 a {
    background-position: 0 0;
}

#menu-item-2087 a {
    background-position: 32px 0;
}

#menu-item-2087 a:hover {
    background-position: 32px 32px;
}

#menu-item-2080 a:hover,
#menu-item-2080.active a {
    background-position: 0px 32px;
}
//Navbar style ENDs




.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  font-size: 15px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $black;
  color: $white;
  text-align: center;
  height: 92px;
  padding: 0.5rem 1rem;
  .logo{
    height: 66px;
  }
  .app-link{
    padding: 1.5rem 1rem;
    display: flex;
    align-content: center;
    justify-content: center;

    .logo{
      height: 36px;
      border-radius: .5rem;
      border: 1px solid $white;
      margin: 0rem .25rem;
    }
  }
}
