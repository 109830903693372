/** Category bar **/
.category-bar-wrapper{
  flex-direction: row;
  flex-wrap: wrap;
}

.category-dropdown{
  background-color: $white;
  color:$white;
  border: 0px;
  font-family: Atlantis the lost City;
}
.category-dropdown > div{
  line-height: 1.5rem;
}
.category-dropdown:hover{
  background-color: $white;
  color: $white;
  border: 1px;
}
.category-map-icon{
  background-color: $white;
  width: 19px;
  height: 19px;
  mask-size: contain;
  mask-repeat: no-repeat;
}
.sub-menu.category-map-icon{
  background-color: $black;
}

.category-dropdown:not(:disabled):not(.disabled):active,
.category-dropdown:not(:disabled):not(.disabled).active,
.show > .category-dropdown.dropdown-toggle
{
  //background:  none !important;
  color: $white !important;
  border: 1px;
  box-shadow: 0 0 0 0.1rem rgba(130, 138, 145, 0.5)!important;
}
.dropdown.show .dropdown-toggle .category-map-icon, .dropdown-toggle:hover .category-map-icon{

}
.category-dropdown-wrapper{
    padding: .5rem;
    border-radius: 0.25rem;
}
.categories_going_out{
  mask-image: url("/assets/categories/categories_going_out.svg");
}
.categories_bars{
  mask-image: url("/assets/categories/categories_bars.svg");
}
.categories_maquis{
  mask-image: url("/assets/categories/categories_maquis.svg");
}
.categories_cafes{
  mask-image: url("/assets/categories/categories_cafes.svg");
}
.categories_caves{
  mask-image: url("/assets/categories/categories_caves.svg");
}
.categories_clubs, .categories_discos{
  mask-image: url("/assets/categories/categories_clubs.svg");
}
.categories_restaurants{
  mask-image: url("/assets/categories/categories_restaurants.svg");
}
.categories_event, .categories_stvalentin{
  mask-image: url("/assets/categories/categories_event.svg");
}
.categories_sponsoring{
  mask-image: url("/assets/categories/categories_sponsoring.svg");
}
.categories_birthdays{
  mask-image: url("/assets/categories/categories_birthdays.svg");
}
.categories_concert{
  mask-image: url("/assets/categories/categories_concert.svg");
}
.categories_sport{
  mask-image: url("/assets/categories/categories_sport.svg");
}
.categories_tasting{
  mask-image: url("/assets/categories/categories_tasting.svg");
}
.categories_foot{
  mask-image: url("/assets/categories/categories_foot.svg");
}
.categories_gastro{
  mask-image: url("/assets/categories/categories_gastro.svg");
}
.categories_karaoke{
  mask-image: url("/assets/categories/categories_karaoke.svg");
}
.categories_launches{
  mask-image: url("/assets/categories/categories_launches.svg");
}
.categories_launches{
  mask-image: url("/assets/categories/categories_launches.svg");
}
.categories_dancing_nights{
  mask-image: url("/assets/categories/categories_dancing_nights.svg");
}
.categories_private_party{
  mask-image: url("/assets/categories/categories_private_party.svg");
}
.categories_vip{
  mask-image: url("/assets/categories/categories_vip.svg");
}
.categories_where_to_stay{
  mask-image: url("/assets/categories/categories_where_to_stay.svg");
}
.categories_hotels{
  mask-image: url("/assets/categories/categories_hotels.svg");
}
.categories_houses{
  mask-image: url("/assets/categories/categories_houses.svg");
}
.categories_lounges{
  mask-image: url("/assets/categories/categories_lounges.svg");
}
.categories_rooms{
  mask-image: url("/assets/categories/categories_rooms.svg");
}
.categories_distributer{
  mask-image: url("/assets/categories/categories_distributer.svg");
}
.categories_distributers{
  mask-image: url("/assets/categories/categories_distributers.svg");
}
.categories_petrol_stations{
  mask-image: url("/assets/categories/categories_petrol_stations.svg");
}
.categories_supermarkets{
  mask-image: url("/assets/categories/categories_supermarkets.svg");
}
.categories_where_to_sleep{
  mask-image: url("/assets/categories/categories_where_to_sleep.svg");
}
.categories_covid19{
  mask-image: url("/assets/categories/categories_covid19.svg");
}
.categories_livraison{
  mask-image: url("/assets/categories/categories_livraison.svg");
}
.categories_superette{
  mask-image: url("/assets/categories/categories_superette.svg");
}
.categories_cinema{
  mask-image: url("/assets/categories/categories_cinema.svg");
}
.categories_centreloisirs{
  mask-image: url("/assets/categories/categories_centreloisirs.svg");
}
.categories_karoke{
  mask-image: url("/assets/categories/categories_karoke.svg");
}


//Colors
.blue{
  .dropdown-item:hover, .dropdown-item:focus{
    background-color: rgba(34,	48,	168, .3)
  }
}
.red{
  .dropdown-item:hover, .dropdown-item:focus{
    background-color: rgba(168,	34,	130, .3)
  }
}
.green{
  .dropdown-item:hover, .dropdown-item:focus{
    background-color: rgba(49, 177, 150, .3)
  }
}
.grey{
  .dropdown-item:hover, .dropdown-item:focus{
    background-color: rgba(36, 51, 36, .3)
  }
}
.purple{
  .dropdown-item:hover, .dropdown-item:focus{
    background-color: rgba(123, 31, 102, .3)
  }
}


/** search bar **/

@include media-breakpoint-down(sm) {
  .search-bar{
    width: 100%;
  }
}
@include media-breakpoint-up(sm) {
  .search-bar{
    width: auto;
    max-width: 200px;
  }
}

/** Map **/
.marker-wrapper{
  width: 34px;
  height: 40px;
  mask-image: url("/assets/marker.png");
  .categories_place{
    mask-image: url("/assets/categories/categories_place.svg");
    width: 34px;
    height: 34px;
    mask-position:center;
    mask-repeat:no-repeat;
    background-color:$white;
  }
}
