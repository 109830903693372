/** Main **/
b, strong{
  font-family: Calibri-Bold;
  font-weight: normal;
  font-style: normal;
}
h1{
  font-family: Atlantis the lost City;
  font-size: 37px;
}
h2{
  font-family: Calibri-Bold;
  font-size: 40px;
  font-weight: normal;
}
